.unit-page-layout-v1 {


	.unit-first-img {
		width: 100%;
		height: 70vh;
		overflow: hidden;
		max-height: 850px;

		@media(max-width: 767px) {
			height: 40vh;
		}


		.cover-img {
			height: 850px;
			background-size: cover;
			background-position: center;
			background-attachment: fixed;

			@media (max-width: 767px) {
				height: calc(100vh - 85px);
			}
		}

		img {
			width: 100%;
			position: fixed;
			object-fit: cover;
			height: 70vh;

			@media(max-width: 767px) {
				height: 40vh;
			}
		}
	}

	.unit-title-section {
		padding: 30px 0;
		background: var(--bg-page);
		position: relative;

		.unit-feedback {


			div {
				display: inline;
				font-weight: 600;
			}
		}

		h3 {
			color: #1c1c1c;
			font-weight: 600;
			font-size: 35px;
			text-transform: uppercase;

			@media (max-width: 767px) {
				font-size: 22px;
				//text-align: center;
				margin: 0 0 40px 0;
			}
		}

		.left {
			@media all and (min-width: 768px) and (max-width: 991px) {
				flex: 0 0 40%;
				max-width: 40%;
			}
		}

		.btn-bookingmask {
			margin: 30px 0 0;

			.btn {
				max-width: 250px;
				width: 100%;
			}
		}

		.unit-fav {
			display: flex;
			align-items: center;
			color: #000;
			font-size: 15px;
			font-weight: 500;
			margin: 15px 0 0;

			@media (max-width: 767px) {
				margin: 15px 0 30px 0;
			}

			/*@media all and (min-width: 768px) and (max-width: 1199px) {
				margin: 50px 0 0px 0;
			}*/

			.fav-icon {
				border: none;
				background: transparent;
				padding: 0;
			}

			i {
				margin: 0 7px 0 0;
			}
		}

		.eckdaten-section {
			margin: 0 0 0 0;
			display: flex;
			flex-wrap: wrap;

			@media all and (min-width: 768px) and (max-width: 991px) {
				flex: 0 0 60%;
				max-width: 60%;
			}

			.eckdaten-section-left {
				width: 40%;
				padding-right: 10px;

				@media (max-width: 1200px) {
					width: 30%;
					text-align: left;
				}

				@media (max-width: 991px) {
					width: 25%;
					text-align: left;
				}

				/*@media all and (min-width: 768px) and (max-width: 991px) {
					width: 40%;
				}*/

				h3 {
					font-size: 15px;
					font-weight: 500;
					width: 100%;
					border-top: 1px solid var(--color-accent);
					padding: 12px 0 0 0;


				}
			}

			.eckdaten-section-right {
				width: 60%;
				padding-left: 10px;

				@media (max-width: 1200px) {
					width: 70%;
				}

				@media (max-width: 991px) {
					width: 75%;
				}

				/*	@media all and (min-width: 768px) and (max-width: 991px) {
					width: 60%;
				}*/

				ul {
					display: flex;
					flex-wrap: wrap;
					margin: 0;
					border-top: 1px solid var(--color-accent);
					margin: 0;
					width: 100%;
					font-size: 14px;
					color: #000;
					font-weight: 400;
					margin-bottom: 50px;



					li {
						width: 50%;
						border-bottom: 1px solid var(--color-accent);
						padding: 10px 0;

						@media (max-width: 767px) {
							width: 100%;
						}

						&.icondog {
							width: 100%;
						}

						.fa-icon {
							margin-right: 10px;

							img {
								width: 20px;
							}
						}
					}
				}
			}


			p {
				font-size: 15px;
				font-weight: 400;
			}

		}
	}

	.unit-buchen {
		padding: 50px 0;
		position: relative;
		background: var(--bg-page);

		@media (max-width: 767px) {
			padding-bottom: 0;
		}

		.row {
			margin-left: -10px;
			margin-right: -10px;

			.col-lg-6 {
				padding-left: 10px;
				padding-right: 10px;
			}
		}

		.asd__wrapper {
			h3 {
				font-size: 22px !important;
			}
		}

		h3 {
			color: #1c1c1c;
			font-weight: 600;
			font-size: 35px;
			text-transform: uppercase;

			@media (max-width: 767px) {
				font-size: 30px;
			}
		}

		.buchen-title {
			border-top: 1px solid var(--color-accent);
			padding: 10px 0 45px;
			border-bottom: 1px solid var(--color-accent);

			@media (max-width: 991px) {
				border-bottom: 0px solid var(--color-accent);
				padding-bottom: 15px;
			}
		}

	}

	.unit-place {
		position: relative;
		background: var(--bg-page);

	}

	.unit-sec-section {
		width: 100%;
		height: 700px;
		position: relative;
		background: var(--bg-page);

		@media (max-width: 991px) {
			height: 500px;
		}

		@media (max-width: 767px) {
			height: 400px;
		}

		.img1 {
			width: 100%;
			height: 700px;
			object-fit: cover;
			position: relative;

			@media (max-width: 991px) {
				height: 500px;
			}

			@media (max-width: 767px) {
				height: 400px;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

	}

	.unit-tabs {
		.nav-tabs {
			padding: 40px 0 80px 0;
			display: flex;
			align-items: center;
			justify-content: center;
			border-bottom: 1px solid var(--color-accent);

			@media (max-width: 767px) {
				width: 100%;
				margin-bottom: 20px;
				padding-bottom: 40px;
			}

			li {
				width: 25%;
				padding: 0 15px;

				@media (max-width: 992px) {
					width: 50%;
					margin: 5px 0;
					padding: 0 5px;
				}

				a {
					border: 1px solid #1c1c1c;
					display: -webkit-box;
					display: flex;
					width: 100%;
					height: 40px;
					text-align: center;
					justify-content: center;
					color: #1c1c1c;
					text-transform: uppercase;
					align-items: center;
					text-decoration: none;
					background: #fff;
					//border: none;
					font-weight: 500;
					font-size: 14px;
					border-radius: 100px;

					@media (max-width: 400px) {
						font-size: 13px;
					}

					&.active {
						background: #1c1c1c;
						color: #fff;
					}


					&:hover {
						background: #1c1c1c;
						color: #fff;
					}
				}
			}
		}

		.tab-content {
			.tab-pane {
				padding: 50px 0;
				/* border-bottom: 1px solid #dee2e6; */

				@media (max-width: 767px) {
					padding: 0 0 40px 0;
				}

				.Grundriss-tab {

					img {
						width: 100%;
						margin-bottom: 30px;


					}
				}
			}
		}

		.unit-properties {
			.tick-li {
				margin: 0;
				display: flex;
				flex-wrap: wrap;
				margin: 0px;
				position: relative;

				&:after {
					display: block;
					content: " ";
					position: absolute;
					width: 100%;
					bottom: 0;
					background: #ccc;
					height: 1px;
				}

				li {
					width: 50%;
					border-bottom: 1px solid #ccc;
					color: #1c1c1c;
					padding: 7px 10px 7px 0;

				}
			}

		}
	}

	.unit-photos {
		padding: 0 0 50px;
		position: relative;
		background: var(--bg-page);

		@media (max-width: 767px) {
			padding-top: 30px;
			padding-bottom: 0;
		}
	}

	.unit-tabs {
		padding: 0 0 30px;
		position: relative;
		background: var(--bg-page);
	}

	.unit-map-bl {
		padding: 0 0 50px;
		position: relative;
		background: var(--bg-page);

		.bdr {
			border-top: 1px solid var(--color-accent);
			padding-top: 50px;
		}

		.leaflet-container.leaflet-touch-zoom {
			min-height: 350px !important;
		}
	}



	.heading3 {
		color: #1c1c1c;
		font-weight: 600;
		font-size: 35px;
		text-transform: uppercase;

		@media (max-width:767px) {
			font-size: 30px;
		}
	}



	.unit-functionality {
		padding: 30px 0 50px 0;
		position: relative;
		background: var(--bg-page);

		.mb-3 {
			@media (max-width: 991px) {
				margin-bottom: 30px !important;
			}
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		p {
			font-size: 15px;
			font-weight: 400;
			padding: 15px 0 15px 0;
			border-top: 1px solid var(--color-accent);
			border-bottom: 1px solid var(--color-accent);
			margin-bottom: 0;
		}

		h3 {
			font-size: 15px;
			font-weight: 500;
			height: 100%;
			padding: 15px 0 0 0;
			border-top: 1px solid var(--color-accent);
			border-bottom: 1px solid var(--color-accent);
		}
	}
}