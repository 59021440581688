.index-search {
	padding: 25px 0 0 0 !important;
	position: absolute;
	bottom: 50px;
	left: 0;
	width: 100%;

	@media (max-width: 375px) {
		bottom: 20px;
	}

	.row {
		margin-left: 0px;
		margin-right: 0px;
		background: rgb(255 255 255 / 85%);
		padding: 20px;

		@media (max-width: 767px) {
			padding: 15px;
		}

		.col-lg-6 {
			padding-left: 10px;
			padding-right: 10px;

			@media (max-width: 767px) {
				padding: 0px;
			}
		}

	}

	h1 {
		height: 100%;
		padding-top: 20px;
		padding-bottom: 20px;
		border-top: 1px solid #000;
		border-bottom: 1px solid #000;
		margin: 0;
		font-size: 15px;
		font-weight: 500;

		@media (max-width: 767px) {
			border-bottom: unset;
			padding-top: 0;
			padding-bottom: 15px;
			border-top: none;
		}

		@media all and (min-width: 768px) and (max-width: 992px) {
			border-bottom: unset;
		}

	}
}

.searchbar-v1 {
	position: relative;
	width: 100%;

	/* @media (max-height: 600px) {
		top: 350px;
	} */


	.title {
		color: #fff;
		background-color: var(--color-secondary);
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		padding: 10px 20px;
		opacity: 0.8;

	}

	.search-form-area {
		display: flex;
		-webkit-box-align: center;
		align-items: center;
		-webkit-box-pack: justify;
		justify-content: space-between;
		background-color: transparent;
		border-bottom: 0px solid #eaeaea;
		width: 100%;

		@media (max-width: 767px) {
			flex-wrap: wrap;
		}

		/*box-shadow: 0 2px 12px rgb(254 154 0 / 25%);*/
		.guestbox {
			margin-top: 15px !important;

			.gb-td.label {
				padding: 5px 0;
				margin: 0;
				color: #000;
				text-transform: uppercase;
				font-weight: 500;
				font-size: 13px;
				padding-right: 30px;

				label {
					margin-bottom: 0 !important;
				}
			}

			.btn.btn-default {
				width: 40px;
				height: 40px;
				border-radius: 50% !important;
				font-size: 25px;
				font-family: revert;
			}

			.gb-td {
				padding: 0 10px;
			}
		}

		.box {
			.body {
				padding: 0 !important;

				.guestbox-title {
					padding: 20px 0;
					font-size: 15px;
					font-weight: 600;
					border-bottom: 1px solid #ccc !important;
				}
			}

			.asd__week {
				border-bottom: 1px solid #ccc;
			}

			.footer {
				border-top: none;
				padding: 20px 15px 20px 15px;
			}

			.asd__month-name {
				font-size: 18px;
				margin: 0 0 53px;
				line-height: 1.4em;
				font-weight: 800;
				text-transform: uppercase;
			}

			.asd__change-month-button>button {
				border: none;
				padding: 0;
			}

			.asd__change-month-button>button>svg {
				fill: #000;
			}

			.asd__day-title {

				color: #000;
				font-size: 14px;
				text-transform: uppercase;
				margin-left: -1px;
				border-bottom: 1px solid #ccc;
				padding-bottom: 10px !important;
				border-top: 1px solid #ccc;
				padding-top: 10px !important;
				font-weight: 600;
			}

			.asd__day-button {
				font-size: 14px;
				font-weight: 600;
			}

			.datepicker-trigger tr td {
				border: none !important;
			}

			.btn.btn-primary {
				background: transparent;
				color: #000;
				font-size: 14px;
				border-radius: 100px !important;
				padding: 5px 20px;
				line-height: normal;
				border-color: #ccc;

				&:hover {
					background: #000;
					color: #fff;
					border-color: #000;
				}
			}
		}

		.search-form-inner {
			display: flex;
			width: 80%;

			@media (max-width: 767px) {
				flex-wrap: wrap;
				width: 100%;
			}
		}

		.asd__wrapper {
			border: none;
		}

		.asd__month {}


		.form-flex {
			width: 50%;
			padding: 0;
			border: none;
			border-right: unset;
			position: relative;
			padding: 20px 0;
			border-top: 1px solid #000;
			border-bottom: 1px solid #000;
			margin: 0 10px;

			@media (max-width: 767px) {
				width: 100% !important;
				margin: 0 !important;
			}

			&.first-filed {
				width: 50%;
			}

			&.sec-filed {
				width: 23%;
			}

			&.third-filed {
				width: 50%;

				.box {
					padding: 0 15px !important;
				}

				.footer {
					border-top: 1px solid #ccc !important;
					padding: 15px 0 10px 0 !important;
					margin-top: 10px !important;
				}
			}



			&.v2 {
				width: 45%;
			}

			@media (max-width:767px) {
				width: 100%;
				border-bottom: 1px solid var(--color-accent);
				border-right: none;
				padding: 6px 12px;

				&.v2 {
					width: 100%;
				}
			}

			@media (max-width: 767px) {
				padding: 0 0 10px 0;
				margin-bottom: 0;
				border-bottom: unset;
			}

			.fa {
				position: absolute;
				right: 0;
				top: 12px;
				font-size: 13px;
				display: none;
			}

			.form-control {
				background-color: transparent;
				text-align: left;
				font-size: 14px;
				border: 0 solid #1c1c1c;
				border-right: none;
				border-left: none;
				height: auto;
				color: #000;
				font-weight: 500;
				padding: 35px 0 0 10px;
				cursor: pointer;
				min-height: 80px;

				@media (max-width: 767px) {
					padding: 60px 0 15px 10px;
				}
			}

			.filed-label {
				color: var(--black);
				font-size: 13px;
				text-transform: uppercase;
				display: block;
				font-weight: normal;
				letter-spacing: 1.5px;
				border: 1px solid #000;
				padding: 2px 25px;
				border-radius: 100px;
				display: inline-block;
				background-color: #000;
				color: #fff;
				font-weight: 500;
				margin-bottom: 0;
				position: absolute;
				left: 0;
				top: 20px;

				@media all and (min-width: 768px) and (max-width: 1199px) {}

			}

			.form-flex-area {
				position: relative;
			}
		}

		.datepicker-trigger {
			.asd__wrapper {
				@media (max-width: 767px) {
					margin: 0 auto;
				}
			}
		}

		.form-btn {
			width: 20%;
			padding-right: 0;
			margin-left: 10px;
			margin-right: 0px;
			flex: 1;
			height: 100%;

			@media (max-width:767px) {
				width: 100%;
				padding: 0px;
				margin: 0;
			}

			.search-btn {
				width: 101%;
				height: auto;
				align-items: center;
				display: flex;
				justify-content: center;
				border-radius: 0;
				background-color: transparent;
				font-size: 20px;
				padding: 35px 0;
				border: none;
				color: #fff;
				text-decoration: none;
				/* background-image: url(RESOURCE/img/search-icon.svg); */
				font-size: 0;
				background-repeat: no-repeat;
				background-size: 45px;
				background-position: center;
				border-top: 1px solid #000;
				border-bottom: 1px solid #000;
				background: #000;

				/* @media all and (min-width: 992px) and (max-width: 1199px) {
					height: 40px;
					background-size: 35px;
				} */

				@media (max-width: 767px) {
					padding: 10px 0;
				}

				img {
					width: 50px;
					filter: invert(1);

					@media (max-width: 767px) {
						width: 25px;
					}

				}

				/* @media (max-width:992px) {
					height: 40px;
					font-size: 20px;
					width: 100%;
				} */
			}
		}
	}

	.form-control:disabled,
	.form-control[readonly] {
		background-color: var(--color-white);
	}

	.list-group-item {
		color: var(--font-color-main);
		text-decoration: none;
		border-radius: 0;

		&:hover {
			color: var(--font-color-main);
			text-decoration: none;
		}

		&.active {
			background-color: transparent;
			border: 1px solid #000;
		}
	}
}