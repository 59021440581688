.search-page-layout-v1 {
	/* background-color: var(--color-grey-light); */
	/* margin-top: var(--page-margin-top); */
	padding-top: 100px !important;

	.guestbox {
		margin-top: 15px !important;

		.gb-td.label {
			padding: 5px 0;
			margin: 0;
			color: #000;
			text-transform: uppercase;
			font-weight: 500;
			font-size: 13px;
			padding-right: 30px;

			label {
				margin-bottom: 0 !important;
			}
		}

		.btn.btn-default {
			width: 40px;
			height: 40px;
			border-radius: 50% !important;
			font-size: 25px;
			font-family: revert;
		}

		.gb-td {
			padding: 0 10px;
		}
	}

	.wann {
		.body {
			padding: 0 !important;
		}
	}

	.wie-viele {
		.box {
			padding: 0 15px;

			.footer {
				padding: 15px 0;
				border-top: 1px solid #ccc !important;
				margin-top: 10px;
			}

			.body {
				padding: 0 !important;

				.guestbox-title {
					padding: 20px 0;
					font-size: 15px;
					font-weight: 600;
					border-bottom: 1px solid #ccc !important;
				}
			}
		}
	}

	.box {

		.datepicker-ext-row {
			margin-bottom: 15px !important;
			margin-top: 10px !important;

			>span {
				color: #000 !important;
			}
		}

		.footer {
			border: none !important;
		}

		.asd__wrapper {
			border: none !important;
		}

		.asd__week {
			border-bottom: 1px solid #ccc;
		}

		.asd__month-name {
			font-size: 18px;
			margin: 0 0 53px;
			line-height: 1.4em;
			font-weight: 800;
			text-transform: uppercase;
		}

		.asd__change-month-button>button {
			border: none;
			padding: 0;
		}

		.asd__change-month-button>button>svg {
			fill: #000;
		}

		.asd__day-title {

			color: #000;
			font-size: 14px;
			text-transform: uppercase;
			margin-left: -1px;
			border-bottom: 1px solid #ccc;
			padding-bottom: 10px !important;
			border-top: 1px solid #ccc;
			padding-top: 10px !important;
			font-weight: 600;
		}

		.asd__day-button {
			font-size: 14px;
			font-weight: 600;
		}

		.datepicker-trigger tr td {
			border: none !important;
		}

		.btn.btn-primary {
			background: transparent;
			color: #000;
			font-size: 14px;
			border-radius: 100px !important;
			padding: 5px 20px;
			line-height: normal;
			border-color: #ccc;

			&:hover {
				background: #000;
				color: #fff;
				border-color: #000;
			}
		}

	}

	.search-left {
		display: flex;
		flex-wrap: wrap;
		position: sticky;
		top: 0;
		z-index: 1;

		.button-group {
			display: flex;
			flex-wrap: wrap;
			width: 100%;
			margin: 23px 0 0 0;

			@media all and (min-width: 768px) and (max-width: 1199px) {
				margin: 20px 0;
			}

			.first-btn {
				width: 50%;
				margin: 0 !important;
				padding-right: 8px;

				@media (max-width: 767px) {
					width: 100%;
					margin: 0 !important;
					padding-right: 0;
					margin-bottom: 0 !important;
				}
			}

			.sec-btn {
				width: 50%;
				margin: 0 !important;
				padding-left: 8px;

				@media (max-width: 767px) {
					width: 100%;
					margin: 0 !important;
					padding-right: 0;
					margin-bottom: 20px !important;
					padding-left: 0;
				}
			}


			.btn-block {
				width: 100%;
				background: transparent;
				color: #000;
				border-color: var(--color-black);
				font-size: 14px;
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 100px !important;
				padding: 8px 15px;
				text-transform: uppercase !important;

				&:hover {
					background: #000;
					color: #fff;
					border-color: #000;
				}
			}
		}

		.ihre-suche {
			width: 50%;
			padding-right: 15px;

			@media (max-width: 991px) {
				width: 100%;
				padding-right: 0;
			}

			h3 {
				font-size: 15px;
				font-weight: 500;
				text-transform: uppercase;
				padding: 15px 0 30px 0;
				margin-bottom: 0;
				border-top: 1px solid var(--color-accent);
				border-bottom: 1px solid var(--color-accent);
			}
		}

		.ihre-ausstattung {
			width: 50%;
			padding-right: 0;

			@media (max-width: 991px) {
				width: 100%;
				padding-right: 0;
				margin-top: 30px;
			}

			h3 {
				font-size: 15px;
				font-weight: 500;
				text-transform: uppercase;
				padding: 15px 0 30px 0;
				margin-bottom: 0;
				border-top: 1px solid var(--color-accent);
				border-bottom: 1px solid var(--color-accent);
			}

			.search-bar-content {


				.label-li {
					margin: 0;
					display: flex;
					flex-wrap: wrap;

					li {
						width: 50%;
						border-bottom: 1px solid var(--color-accent);
						display: flex;
						min-height: 55px;
						align-items: center;
						font-size: 13px;
						font-weight: 500;
						text-transform: uppercase;

						label {
							margin-bottom: 0 !important;
						}

						.checkbox {
							display: inline-block;
							width: 100%;
							cursor: pointer;
						}

						.checkbox label:before {
							width: 15px;
							height: 15px;
							border: 1px solid #000;
							border-radius: 3px !important;
							background-color: transparent;
						}

						.checkbox label:after {
							font-size: 0;
						}
					}
				}
			}
		}
	}

	.search-left-sidebar {
		@media all and (min-width: 992px) and (max-width: 1199px) {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}

	.search-right {
		@media all and (min-width: 992px) and (max-width: 1199px) {
			flex: 0 0 100%;
			max-width: 100%;
		}

		h3 {
			font-size: 15px;
			font-weight: 500;
			text-transform: uppercase;
			padding: 15px 0 30px;
			margin-bottom: 0;
			border-top: 1px solid var(--color-accent);
			border-bottom: 1px solid var(--color-accent);
		}

		.alert {
			text-transform: uppercase;
			font-size: 14px;
			font-weight: 500;
		}

		.search-tocken {
			.my-search-token {

				border: 1px solid #000;
				background-color: transparent;
				font-size: 14px;
				color: #000;
				font-weight: 400;
				text-transform: uppercase;
				font-size: 14px;
				border-radius: 0;
			}
		}

		.flex {
			border-top: 1px solid var(--color-accent);
			padding-top: 30px;
		}

		.more-btn-panel {
			margin: 30px 0 0 0;
			padding: 30px 0 0 0;
			border-top: 1px solid var(--color-accent);


			.btn-secondary {
				background: transparent;
				color: #000;
				border-color: #fff;
				font-size: 14px;
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 100px !important;
				padding: 8px 15px;
				text-transform: uppercase !important;
			}
		}
	}
}