.feedbacks-v1 {
	.total-review-bl {

		.cl-rat {
			i {
				background-image: url(RESOURCE/img/star_icon_yellow.svg);
				background-size: contain;
				height: 20px;
				width: 20px;
				background-repeat: no-repeat;
			}
		}
	}

	.owl-carousel {
		/*background: var(--color-white);*/
		padding: 0 0px;
		border-radius: 5px;
		/*margin-bottom: 50px;*/

		@media (max-width: 767px) {
			padding: 0 20px;
		}

		.cl-rat {
			margin: 20px 0 10px;
			text-align: left;

			i {
				color: var(--color-dark-blue);
			}
		}

		.cl-dt {
			a {
				//font-size: 13px;
			}
		}

		.owl-dots {
			display: none;

		}

		.owl-nav {
			button {
				position: absolute;
				top: 140px;

				font-size: 55px;
				line-height: 0;
				opacity: 0.3;
				margin-top: -10px;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);

				&.owl-next {
					right: 0;

					@media(min-width: 768px) {
						right: -25px;
					}
				}

				&.owl-prev {
					left: 0;

					@media(min-width: 768px) {
						left: -25px;
					}
				}
			}
		}

		h3,
		.h3 {
			margin: 0 0 20px 0;
			text-transform: uppercase;
		}

		.cl-cont-bl {
			/*box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;*/
			margin: 0;
			padding: 35px 25px 25px 25px;
			background: var(--color-white);
			border-radius: 5px;
			height: 100%;

			h2 {
				font-size: 1rem;
				text-align: left;
			}

			.fb-text {
				display: -webkit-box;
				-webkit-box-orient: vertical;
				overflow: hidden;
				line-height: 1.5;
				-webkit-line-clamp: 8;
				/* Anzahl der Zeilen, die angezeigt werden */
				position: relative;
				font-size: 14px;
			}

			.author {
				font-weight: 600;
				padding-top: 10px;
				font-size: 14px;
			}

			/*&:before {
				content: "''";
				position: absolute;
				top: 70px;
				left: 40px;
				font-size: 50px;
				color: #000;
				line-height: 20px;

				@media (max-width: 767px) {
					top: 20px;
				}
			} */

		}
	}

}

.testimonial-slide {
	.owl-stage {
		display: flex;

		.owl-item {
			.item {
				height: 100%;
				padding: 0px 0px;

				@media (min-width: 767px) {
					/*margin-left: 25px;*/
				}

				@media (max-width: 767px) {
					padding: 0 22px 20px 22px;
				}

				.cl-cont-bl {}
			}
		}
	}
}