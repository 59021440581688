.unit-booking-v1 {

	margin: 0;
	/* border-top: 1px solid var(--color-accent); */
	/* padding: 10px 0 20px; */
	/* border-bottom: 1px solid var(--color-accent); */



	.search-bl {
		position: relative;

		.fa {
			position: absolute;
			right: 15px;
			top: 15px;
			color: var(--color-grey-dark);
		}
	}

	.sec-filed {
		.box {
			padding: 0 15px;

			.footer {
				padding: 15px 0 !important;
				border-top: 1px solid #ccc !important;
				margin-top: 10px;
			}

			.body {
				padding: 0 !important;

				.guestbox-title {
					padding: 20px 0;
					font-size: 15px;
					font-weight: 600;
					border-bottom: 1px solid #ccc !important;
				}
			}
		}
	}

	.search-bl {

		.body {
			padding: 0 !important;
		}

		.datepicker-ext-row {
			margin-bottom: 15px !important;
			margin-top: 10px !important;
		}

		.footer {
			border: none !important;
		}

		.asd__wrapper {
			border: none !important;
		}

		.asd__week {
			border-bottom: 1px solid #ccc;
		}

		.asd__month-name {
			font-size: 18px;
			margin: 0 0 53px;
			line-height: 1.4em;
			font-weight: 800;
			text-transform: uppercase;
		}

		.asd__change-month-button>button {
			border: none;
			padding: 0;
		}

		.asd__change-month-button>button>svg {
			fill: #000;
		}

		.asd__day-title {

			color: #000;
			font-size: 14px;
			text-transform: uppercase;
			margin-left: -1px;
			border-bottom: 1px solid #ccc;
			padding-bottom: 10px !important;
			border-top: 1px solid #ccc;
			padding-top: 10px !important;
			font-weight: 600;
		}

		.asd__day-button {
			font-size: 14px;
			font-weight: 600;
		}

		.datepicker-trigger tr td {
			border: none !important;
		}

		.btn.btn-primary {
			background: transparent;
			color: #000;
			font-size: 14px;
			border-radius: 100px !important;
			padding: 5px 20px;
			line-height: normal;
			border-color: #ccc;

			&:hover {
				background: #000;
				color: #fff;
				border-color: #000;
			}
		}

	}

	.search-form {

		.btn.btn-go,
		.btn.btn-primary,
		.btn-default {
			border-radius: 100px !important;
			text-transform: uppercase;
			font-size: 14px;
		}

		.guestbox {
			margin-top: 15px !important;

			.gb-td.label {
				padding: 5px 20px;
				margin: 0;
				color: #000;
				text-transform: uppercase;
				font-weight: 500;
				font-size: 13px;

				label {
					margin-bottom: 0 !important;
				}
			}

			.btn.btn-default {
				width: 40px;
				height: 40px;
				border-radius: 50% !important;
				font-size: 25px;
				font-family: revert;
			}

			.gb-td {
				padding: 0 10px;
			}
		}

		.box {
			.body {
				padding: 0 !important;
			}

			.asd__week {
				border-bottom: 1px solid #ccc;
			}

			.footer {
				border-top: none;
				padding: 5px 15px 10px 15px;
			}

			.asd__month-name {
				font-size: 15px;
				margin: 0 0 56px;
				line-height: 1.4em;
				font-weight: 600;
				text-transform: uppercase;
			}

			.asd__change-month-button>button {
				border: none;
				padding: 0;
			}

			.asd__change-month-button>button>svg {
				fill: #000;
			}

			.asd__day-title {

				color: #000;
				font-size: 13px;
				text-transform: uppercase;
				margin-left: -1px;
				border-bottom: 1px solid #ccc;
				padding-bottom: 10px !important;
				border-top: 1px solid #ccc;
				padding-top: 10px !important;
			}

			.asd__day-button {
				font-size: 13px;
				font-weight: 500;
			}

			.datepicker-trigger tr td {
				border: none !important;
			}

			.btn.btn-primary {
				background: transparent;
				color: #000;
				font-size: 14px;
				border-radius: 100px !important;
				padding: 5px 20px;
				line-height: normal;
				border-color: #ccc;

				&:hover {
					background: #000;
					color: #fff;
					border-color: #000;
				}
			}
		}

		.search-form-inner {
			display: flex;
			width: 100%;


			.legend-cal {
				font-size: 12px;

				li:first-child {
					@media (max-width: 992px) {
						width: inherit;
					}
				}

				li {

					font-size: 12px;

					i {
						width: 24px;
						height: 24px;
						display: inline-block;
						margin: 0 10px;
						border: 1px solid gray;

						@media (max-width:992px) {
							width: 15px;
							height: 15px;
							margin: 0 6px 0 0;
						}
					}

					.available {
						background-color: #fff;
					}

					.available-minstay {
						background-color: #cbcbcb;
					}

					.booked {
						background-color: #707070;
					}

				}

				@media (max-width: 992px) {
					display: block;
				}
			}
		}

		.form-flex-area {
			width: 50%;
			position: relative;
			margin-right: 10px;
			border-top: 1px solid var(--color-accent);
			padding: 10px 0 20px;
			border-bottom: 1px solid var(--color-accent);

			@media (max-width: 767px) {
				width: 100%;
				border-bottom: 1px solid var(--color-accent);
				margin-bottom: 20px;
				margin-bottom: 0;
				border-bottom: unset;
				margin-right: 0;
			}

			&.sec-filed {
				margin-right: 0;
				margin-left: 10px;

				@media (max-width: 767px) {
					margin-left: 0;
				}
			}

			.filed-label {
				color: var(--black);
				font-size: 13px;
				text-transform: uppercase;
				display: block;
				font-weight: 400;
				letter-spacing: 1.5px;
				border: 1px solid var(--color-black);
				padding: 2px 25px;
				border-radius: 100px;
				display: inline-block;
				background-color: #000;
				color: #fff;
				font-weight: 500;
				margin-bottom: 15px;
				position: absolute;
				left: 0;
				top: 12px;
			}
		}
	}

	.form-control {
		background-color: transparent;
		text-align: left;
		font-size: 14px;
		border: 0 solid var(--color-black);
		border-right: none;
		border-left: none;
		height: auto;
		color: #000;
		font-weight: 500;
		padding: 45px 0 8px 10px;
		cursor: pointer;
	}

	.form-control::-webkit-input-placeholder {
		/* Chrome/Opera/Safari */
		color: #000;
	}

	.form-control::-moz-placeholder {
		/* Firefox 19+ */
		color: #000;
	}

	.form-control:-ms-input-placeholder {
		/* IE 10+ */
		color: #000;
	}

	.form-control:-moz-placeholder {
		/* Firefox 18- */
		color: #000;
	}

	.table {
		td {
			border-top: 2px solid var(--color-white);
			word-break: break-word;
		}

		tbody {
			border-top: 2px solid var(--color-white);
		}
	}

	.optionals {
		margin-top: 20px;
	}

	.optionals-title {
		font-size: var(--h4-font-size);
		font-weight: var(--h4-font-weight);
		padding-left: 10px;
	}

	.consumptioncosts-title {
		font-size: var(--h5-font-size);
		font-weight: var(--h5-font-weight);
		padding-left: 10px;
	}

	.button-row {
		text-align: right;
		margin-top: 20px;

	}

	.subrow {
		margin-top: 20px;
	}

	.btn-option {
		margin-top: 10px;
	}
}