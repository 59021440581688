.contact-form-v1 {
	.checkbox label {
		vertical-align: inherit;
	}

	.red {
		color: var(--color-red);
	}

	.footer-link {
		color: #1c1c1c;
		text-decoration: underline;
	}

	.form-control {
		border-radius: unset;
	}

	.btn-go {
		background: #1c1c1c;
		border: 1px solid #1c1c1c;
		border-radius: 0;

		&:hover {
			background: transparent;
			color: #1c1c1c;
			border: 1px solid #1c1c1c;
		}
	}
}