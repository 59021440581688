.das-team {

	h2 {
		padding-bottom: 20px;
	}

	.team-member {
		margin-bottom: 50px;


		.image {
			//aspect-ratio: 3/2;
			overflow: hidden;

			@media(min-width: 992px) {
				//max-height: 220px;
			}

			img {
				width: 100%;
			}
		}

		.text {
			position: relative;

			p {
				font-style: italic;
			}

			@media (max-width: 991px) {
				margin-top: 10px;
			}

			.author {
				margin-top: 10px;
				//font-weight: 500;
			}

		}

	}
}