.unitsforpets {
	.unit-list-section {

		.owl-carousel {
			@media(min-width: 768px) {
				display: grid;
				grid-template-columns: repeat(3, 1fr);
				gap: 30px;

			}

			@media(max-width: 991px) {
				grid-template-columns: repeat(2, 1fr);
			}

			@media(max-width: 767px) {
				display: block;
			}



		}
	}

	.owl-carousel {
		.owl-nav {
			button {
				top: 32%;
				position: absolute;
				font-size: 55px;
				opacity: 0.3;
				line-height: 0;
				transform: translateY(-50%);

				@media(max-width: 575px) {
					opacity: 1.0;

					span {
						font-family: "Font Awesome 5 Free";
						font-weight: 900;
						-webkit-font-smoothing: antialiased;
						display: inline-block;
						font-style: normal;
						font-feature-settings: normal;
						font-variant: normal;
						text-rendering: auto;
						line-height: 1;
						color: white;
						background: #000;
						font-size: 30px;
						padding: 10px 10px;
					}
				}

				&.owl-next {
					@media(max-width: 767px) {
						right: -30px;
					}

					@media(max-width: 575px) {
						right: 0px;
					}
				}

				&.owl-prev {
					@media(max-width: 767px) {
						left: -30px;
					}

					@media(max-width: 575px) {
						left: 0px;
					}
				}
			}
		}
	}
}