.facility-page-layout-v1 {
	/* margin-top: var(--page-margin-top); */

	.region-name {
		margin-top: -20px;
		color: var(--color-grey-dark);
	}

	.facility-map {
		width: 100%;
		height: var(--facility-map-height);
		position: relative;
	}

	h1 {
		font-size: 15px;
		font-weight: 500;
		text-transform: uppercase;
		margin: 0 0 40px 0;
	}

	h2 {
		font-size: 15px;
		font-weight: 500;
		text-transform: uppercase;
		margin: 0 0 20px 0;
	}
}