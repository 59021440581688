.faq-v1 {

	.card {
		background-color: transparent;
		border: 0px;
		border-top: 1px solid var(--color-accent);
		border-bottom: 1px solid var(--color-accent);
		border-radius: 0px;

		.card-header {
			padding: 0px;
			background-color: transparent;
			border-bottom: 0px;
		}

		.card-body {
			padding: 0px 0px;
			padding-bottom: 15px;
		}

		.btn-faq {
			border: 0;
			width: 93%;
			font-weight: 600;
			padding: 10px 0px;
			font-size: 16px;

		}

		.fas {
			position: absolute;
			top: 12px;
			right: 10px;
		}

		.active-button {
			color: var(--color-orange) !important;
		}
	}


}