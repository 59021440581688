.blogentry-v1 {
	padding-top: var(--page-margin-top);

	.blogcontent p.lead {
		font-size: var(--font-size-main);
	}

	.img-thumbnail {
		height: 100% !important;
		padding: 0;
		border-radius: 0 !important;
		box-shadow: none;
		border: none;
	}

	.date {
		padding-top: 10px;
		font-size: var(--font-size-md);
		margin-bottom: 30px;
		font-weight: 400;
		font-size: 14px;

	}

	.content-block {
		h1 {
			font-size: 35px;
			color: #000;
			margin: 0 0 0 0;
			text-transform: uppercase;
		}

		p.lead {}

		p {
			font-size: 14px;
			color: #000;
			font-weight: 400;
		}
	}

	.img-col {
		position: relative;

		.percent {
			position: absolute;
			left: 0px;
			font-size: 20px;
			font-weight: bold;
			color: var(--color-white);
			background-color: var(--color-red);
			padding: 10px;
		}
	}

	.offer-title {
		padding: 10px 0px;
		font-size: 1.2rem;
		font-weight: bold;
	}

	.unit-list-section {
		.owl-carousel {
			@media(min-width: 768px) {
				display: grid;
				grid-template-columns: repeat(3, 1fr);
				gap: 30px;

			}

			@media(max-width: 991px) {
				grid-template-columns: repeat(2, 1fr);
			}

			@media(max-width: 767px) {
				display: block;
			}

			.owl-nav {
				button {
					position: absolute;
					font-size: 55px;
					opacity: 0.3;
					line-height: 0;
					transform: translateY(-50%);
					top: 28%;

					@media(max-width: 575px) {
						opacity: 1.0;

						span {
							font-family: "Font Awesome 5 Free";
							font-weight: 900;
							-webkit-font-smoothing: antialiased;
							display: inline-block;
							font-style: normal;
							font-feature-settings: normal;
							font-variant: normal;
							text-rendering: auto;
							line-height: 1;
							color: white;
							background: #000;
							font-size: 30px;
							padding: 10px 10px;
						}
					}

					&.owl-next {
						@media(max-width: 767px) {
							right: -30px;
						}

						@media(max-width: 575px) {
							right: 0px;
						}
					}

					&.owl-prev {
						@media(max-width: 767px) {
							left: -30px;
						}

						@media(max-width: 575px) {
							left: 0px;
						}
					}
				}
			}

		}
	}

	.btn-line {
		@media(max-width: 767px) {
			margin-top: 40px !important;
		}
	}

}