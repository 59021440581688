.contact-page-layout-v1 {

	/* margin-top: var(--page-margin-top); */
	padding-top: 90px;

	@media (max-width: 767px) {
		padding-top: 40px;
	}

	.inner-banner {
		background-image: url(RESOURCE/img/contact-banner1.jpg);
		background-position: top;
	}

	h1 {
		color: #000;
		font-weight: 600;
		font-size: 35px;
		text-decoration: none;
		text-transform: uppercase;
		margin-bottom: 30px !important;
		margin-top: 10px;
		display: block;
		width: 100%;

		@media (max-width: 767px) {
			font-size: 30px;
		}
	}
}