.navbar-v1.affix,
.navbar-v1 {

	position: fixed;
	position: static;
	width: 100%;
	top: 0;
	border-width: 0 0 1px;
	padding: 0;
	z-index: 50;
	/* border-bottom: var(--nav-border-bottom); */
	background-color: transparent;

	@media (max-width:992px) {
		position: absolute;
	}



	&.search-navbar {
		.header-right {
			/* justify-content: flex-end !important; */
		}
	}

	&.booking-nav {
		background: #5a5a5a !important;

		.vo-nav-bar {
			padding: 30px 0;
		}
	}

	&.inner-navbar {
		.header-main {
			padding-bottom: 20px;
			border-bottom: 1px solid var(--color-accent);
		}
	}

	/* &.default-navbar {
		position: absolute;
		width: 100%;
		background: transparent;

		.header-logo {
			a {
				color: #fff !important;
				text-decoration: none;
			}

		}

		.icon .menubar i {
			background: #fff !important;
		}

		.header-right {
			justify-content: flex-end !important;
		}
	} */

	.vo-nav-bar {
		padding: 15px 0;
		position: absolute;
		z-index: 9;
		width: 100%;


		a {
			text-decoration: none;
		}
	}

	.header-right {
		display: flex;
		justify-content: space-between;
		align-items: center;

		@media (max-width: 767px) {
			justify-content: flex-end;
			padding-right: 50px !important;
			padding-top: 5px;
		}

		@media all and (min-width: 768px) and (max-width: 1199px) {
			justify-content: flex-end;
		}

		.h4 {
			font-size: 16px;
			font-weight: 700;
			text-transform: uppercase;
			margin: 0 0 30px 0;
		}

		ul {
			li {
				margin-bottom: 7px;

				a {
					font-size: 14px;
					text-transform: uppercase;
					color: #000;
					font-weight: 400;
				}
			}
		}

	}


	.nav-row {
		position: static;
		margin: 0;

		@media all and (min-width: 768px) and (max-width: 1199px) {
			margin-top: 3px;
			margin-left: 15px;
		}

		.col-12 {
			position: static;
		}
	}

	.input-search-unit {
		padding: 5px 15px;
		max-width: 280px;

		@media (max-width:992px) {
			max-width: 100%;

		}

		@media (max-width:992px) {
			margin-bottom: 5px;
		}

		.form-control {
			padding: 5px 10px;
			width: 200px;

		}

		.form-control::placeholder {
			font-size: 0.875rem;
			color: #aaa;
			font-style: italic;
		}

		.btn {
			border-radius: 0 5px 5px 0;
			padding: 0px 15px;
		}
	}


	.top-nav {
		padding: 3px 0 8px;
		text-align: right;
		font-size: var(--top-nav-font-size);
		font-family: var(--bodyfont);
		background-color: var(--top-nav-bg-color);

		ul {
			padding: 0;
			margin: 0;
			display: inline-flex;
			align-items: center;
			flex-wrap: wrap;
			justify-content: flex-end;

			li {
				display: inline-block;
				position: relative;
				font-size: var(--top-nav-font-size);
				color: var(--top-nav-font-color);

				@media (max-width:992px) {
					display: block;
				}

				a {
					color: var(--top-nav-font-color);
					font-size: var(--top-nav-font-size);
					text-decoration: none;
					padding: 5px 15px;

					@media (max-width:550px) {
						padding: 5px 4px;
					}

					&:hover {
						color: var(--top-nav-font-color-hover)
					}

					i.fa {
						font-size: var(--top-nav-icon-size);
						margin-right: 5px;
					}
				}
			}
		}
	}

	.whatsapp {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;
		width: 100%;
		padding: 0 50px 0 0;

		@media (min-width: 993px) {
			margin-left: 0;
		}

		@media all and (min-width: 993px) and (max-width: 1199px) {
			padding: 0 5px 0 20px;
		}

		@media (max-width: 1199px) {
			padding: 0;
		}

		a {
			display: flex;
			align-items: center;
			color: #000;
			font-weight: 400;
			margin: 0 0 0 50px;

			@media (max-width: 1199px) {
				font-size: 0;
				margin: 0;
			}

			@media all and (min-width: 1200px) and (max-width: 1550px) {
				margin: 0 0 0 25px;
			}
		}

		img {
			width: 18px;
			opacity: 0.7;
			margin-right: 7px;

			@media (max-width: 1199px) {
				margin-right: 0;
				margin-left: 15px;
			}

			@media (max-width: 358px) {

				margin-left: 12px;
			}
		}
	}

	.fav-count {
		a {
			display: flex;
			align-items: center;
			margin: 0;

			@media (max-width: 992px) {
				font-size: 0;
			}
		}

		i {
			margin: 0 7px 0 0;
			font-size: 18px;
			color: #000;
			opacity: 0.7;

			@media (max-width: 992px) {
				margin-right: 0;
				margin-left: 15px;
			}

			@media (max-width: 375px) {

				margin-left: 0px;
			}
		}


		span {
			font-weight: 400;
			text-transform: uppercase;
		}

		.sub-count {
			-moz-border-radius: 50%;
			-webkit-border-radius: 50%;
			border-radius: 50%;
			display: block;
			position: absolute;
			top: -10px;
			left: 3px;
			width: 18px;
			height: 18px;
			background: var(--color-primary);
			color: var(--color-white);
			text-align: center;
			font-size: 12px;
			line-height: 18px;
		}
	}



	.header-main {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.header-logo {
			position: relative;
			padding-left: 0;
			z-index: 20;
			text-transform: uppercase;
			font-weight: 600;
			font-size: 30px;
			width: 50%;

			a {
				color: #000;
				text-decoration: none !important;
				font-size: 40px;
				font-weight: 200;

				@media (max-width: 992px) {
					font-size: 28px;

				}

				@media (max-width: 767px) {
					font-size: 18px;
				}

				@media (max-width: 375px) {
					font-size: 16px;
				}

				@media (max-width: 330px) {
					font-size: 13px;

				}

				@media all and (min-width: 1200px) and (max-width: 1450px) {
					font-size: 32px;
				}

			}

			.logo {
				width: 18vw;
				min-width: 280px;
				max-width: 320px;
				height: auto;
			}

		}

		.main-menu {
			float: right;
			position: fixed;
			left: 0;
			top: 100%;
			background-color: #fff;
			width: 100%;
			max-width: 50%;
			left: auto;
			right: 0;
			top: 0;
			border: none;
			height: 100vh;
			padding: 0 50px 50px 50px;
			display: block !important;
			transform: translateX(100%);
			transition: all 0.35s;
			z-index: 99;
			overflow: auto;

			@media (max-width: 991px) {
				max-width: 50%;
			}

			@media (max-width: 767px) {
				overflow: auto;
				padding: 0 15px 15px 15px;
				max-width: 100%;
			}

			@media (max-width: 480px) {
				z-index: 5;
			}

			&.menuopen {
				transform: translateX(0%);

				ul {
					margin-bottom: 30px !important;
				}
			}

			>ul {
				padding: 0;
				margin: 0 0 0 0;

				>li {
					float: left;
					display: inline-block;
					width: 100%;

					@media (max-width:992px) {
						display: block;
						width: 100%;
					}

					&:last-child {
						margin-right: 0;
					}

					>a {
						color: #000;
						text-transform: var(--main-nav-text-transform);
						font-size: var(--main-nav-font-size);
						padding: 15px 1.2vW;
						line-height: 1;
						display: block;
						font-weight: 400;
						text-decoration: none;
						font-size: 14px;

						@media (max-width:1199px) {
							font-size: var(--main-nav-font-size);
						}

						@media (max-width:992px) {
							padding: 10px 15px;
							font-size: 14px;
						}


						&:hover {
							/*color: var(--main-nav-font-color-hover);
							background-color: var(--main-nav-bg-hover);
							text-decoration: none;*/
							background: #fff;
							color: #1c1c1c;
						}



					}

					&.dropdown {

						cursor: pointer;

						.dropdown-backdrop {
							display: none;
						}


						&.show {
							background-color: #f7f7f7;

							a {
								color: #000;

							}
						}
					}




				}
			}

			.btn {
				@media (max-width:340px) {
					font-size: 12px;
					padding: 8px 8px;
				}
			}
		}

		.openmneutop {
			padding: 60px 0 35px 0;
			font-size: 14px;
			color: #000;
			display: block;

			&.whatsapp {
				margin-bottom: 0;
				justify-content: flex-start;
				margin-left: 0;

				.fav-count {
					margin-bottom: 10px;

					a {
						font-size: 14px !important;
						padding: 0 !important;
					}

					i {
						@media (max-width: 992px) {
							margin-left: 0 !important;
							margin-right: 15px !important;
						}
					}
				}

				a {
					font-size: 14px !important;
					margin: 0;
					padding: 0 0;

					@media (max-width: 1550px) {
						margin-bottom: 10px;
					}
				}

				img {
					@media (max-width: 1450px) {
						margin-left: 0 !important;
						margin-right: 7px !important;
					}
				}
			}

			.col-xl-4 {
				@media all and (min-width: 1200px) and (max-width: 1550px) {
					flex: 0 0 100%;
					max-width: 100%;
				}
			}
		}
	}

	.dropdown-menu {
		border-radius: 0;
		border: none;
		/* box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175); */
		min-width: 400px;
		border-top: 20px solid var(--main-nav-bg-hover);
		margin-top: -1px;
		position: relative !important;
		-webkit-transform: none !important;
		transform: none !important;
		width: 100%;
		border-top: none;

	}

	.sub-nav {

		ul {
			list-style-type: none;
			padding-left: 30px;

			li {

				a {
					padding: 10px 0;
					color: #000 !important;
					font-weight: 400;
					display: block;
					font-size: 14px;
					text-transform: uppercase;
					text-decoration: none !important;

					@media (max-width:992px) {
						padding: 5px 0;
						font-weight: 400;
						text-transform: uppercase;
					}

					&:hover {
						color: #1c1c1c !important;
						text-decoration: none !important;

					}
				}

			}
		}


	}

	.header-btn-group {
		display: flex;
		align-items: center;
		width: 100%;
		padding-right: 30px;

		&.button-hide {
			display: none;
		}

		.btn-primary {
			max-width: 250px;
			width: 100%;
			color: rgb(0, 0, 0);
			position: relative;
			margin: 0px 15px 0 0;
			border-width: 1px;
			border-style: solid;
			border-color: rgb(0, 0, 0);
			border-image: initial;
			border-radius: 0px;
			background: transparent;
			text-decoration: none !important;

			&:hover {
				background: #000;
				color: #fff;
			}

			i {
				position: absolute;
				right: 10px;
				top: 10px;
			}
		}
	}

	.icon {
		display: block;
		text-align: right;
		float: right;
		position: static;
		top: 4px;
		width: 45px;

		@media (max-width: 1199px) {
			width: 40px;
		}

		@media (max-width: 992px) {
			width: 30px;
		}

		.menubar {
			i {
				width: 45px;
				height: 5px;
				background: #000;
				margin-bottom: 7px;
				display: block;
				border-radius: 0px;

				@media (max-width: 1199px) {
					width: 30px;
					height: 3px;
				}

				@media (max-width: 992px) {
					width: 30px;
					height: 3px;
					margin-bottom: 5px;
				}
			}

		}

		.menuclose {
			position: fixed;
			z-index: 100;
			color: #000;
			top: 15px;
			right: 25px;
			left: unset;
			font-size: 30px;

			@media (max-width: 767px) {
				right: 20px;

				top: 25px;
				position: fixed;
				font-size: 24px;
			}

			@media all and (min-width: 768px) and (max-width: 991px) {
				right: -10px;

				top: -3px;
				position: relative;
				font-size: 24px;
			}

			@media all and (min-width: 992px) and (max-width: 1199px) {

				top: 20px;
				right: 20px;
			}

			@media all and (min-width: 1200px) and (max-width: 1350px) {
				right: 25px;
			}
		}
	}

	.navactive {
		@media (min-width:993px) {
			color: var(--main-nav-font-color-hover) !important;
			background-color: var(--main-nav-bg-hover) !important;
		}
	}


	@media screen and (max-width: 992px) {

		.top-nav {
			padding: 10px 0 8px;
			text-align: right;


			.input-search-unit {
				display: none;
			}
		}

		.header-logo {
			padding-left: 0px !important;
		}

		.nav-row {
			min-height: 20px !important;
		}

		.icon {
			display: block;
			bottom: 20px;
		}

		.dropdown-menu {
			position: relative !important;
			transform: none !important;
			width: 100%;
			border-top: none;
		}

		.main-menu {
			background-color: var(--nav-bg-color);
			padding: 5px 0;
			z-index: 50;
			display: none;
			width: 100%;
			border-bottom: 1px solid #61847a;
		}

		.search-unit-ul {
			margin: 15px 0px 0px;

			.input-search-unit {
				.input-group {
					.form-control {
						@media (max-width: 574px) {
							height: 40px !important;
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: 767px) {

		.top-nav {
			text-align: center;

			.input-search-unit {
				display: none;
			}
		}

		.header-main {

			.header-logo {
				position: static;
				text-align: left;
				z-index: unset;
			}

			.nav-row {
				min-height: 20px !important;
			}

			.icon {
				position: absolute;
				bottom: 30px;
				right: 5px;

			}
		}

	}

	@media screen and (min-width: 993px) {
		.main-menu {
			display: none;
		}
	}

	&.affix-top {
		/* border-bottom: var(--nav-border-bottom-affix-top); */
		background-color: var(--nav-bg-color-affix-top);
		z-index: 4;

		.top-nav {
			background-color: var(--top-nav-bg-color-affix-top);

			.input-search-unit {
				@media (max-width:992px) {
					display: none;
				}
			}

			ul {

				li {
					color: var(--top-nav-font-color-affix-top);

					@media (max-width:992px) {
						display: block;
					}

					a {
						color: var(--top-nav-font-color-affix-top);
						font-size: var(--top-nav-font-size);
						text-decoration: none;
						padding: 5px 15px;

						@media (max-width:550px) {
							padding: 5px 4px;
						}

						&:hover {
							color: var(--top-nav-font-color-affix-top-hover);
						}

					}
				}
			}
		}

		.header-main {

			.header-logo {

				.logo {}

			}

			.main-menu {

				>ul {
					padding: 0;
					margin: 0;

					>li {
						>a {
							color: var(--main-nav-font-color-affix-top);
							font-size: 14px;
							color: #000;
							font-weight: 400;
							text-transform: uppercase;

							@media (max-width: 992px) {}


							&:hover {
								color: var(--main-nav-font-color-affix-top-hover);
								background-color: var(--main-nav-bg-affix-top-hover);
								text-decoration: none;
							}



						}

						&.dropdown {

							cursor: pointer;

							.dropdown-backdrop {
								display: none;
							}


							&.show {
								background-color: transparent;

								a {
									color: var(--main-nav-font-color-hover);
								}
							}
						}




					}
				}

				.btn {
					@media (max-width:340px) {
						font-size: 12px;
						padding: 8px 8px;
					}
				}
			}
		}
	}

}

.mobil-menu {
	position: fixed;
	cursor: pointer;
	display: none;
	bottom: 20px;
	right: 5px;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: #fff;
	z-index: 200;
	line-height: 40px;
	text-align: center;
	font-size: 24px;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	-webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	-o-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

	@media (min-width:993px) {
		display: none !important;
	}
}

.open-by-mob {
	#mob-nav-icon {
		display: none;
	}

}