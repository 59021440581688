.blog-card-v1 {
	margin-bottom: 0;
	width: 100%;
	/* box-shadow: 0 0.1rem 1rem rgb(0 0 0 / .1);
	background-color: var(--color-white);
	padding: 10px; */

	&:hover {
		/*box-shadow: 0 4px 25px 0 rgb(0 0 0 / 50%);
		-webkit-transform: translateX(1px);
		transform: translateX(1px);*/
	}

	.info-text {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		overflow: hidden;
		line-height: 1.5;
		-webkit-line-clamp: 4;
		position: relative;
		font-size: 14px;
		height: 63px;

		@media (max-width: 767px) {
			min-height: auto;
		}
	}

	.offer-text {
		margin-top: 10px;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		overflow: hidden;
		line-height: 1.5;
		-webkit-line-clamp: 3;
		position: relative;
		font-size: 14px;
		height: 63px;

		@media (max-width: 767px) {
			min-height: auto;
		}
	}

	.image {
		height: 250px;
		overflow: hidden;
		position: relative;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		.img-thumbnail {
			padding: 0px;
		}
	}

	.blog-body {
		padding-top: 15px;
		padding-bottom: 50px
	}

	/* .date {
		position: absolute;
		font-size: 0.85rem;
		background-color: var(--color-primary);
		padding: 2px 5px;
		color: var(--color-white);
		bottom: 10px;
		right: 10px;

	} */

	.title {
		font-size: var(--blog-card-title-font-size);
		color: var(--blog-card-title-font-color);
		border-top: 1px solid var(--color-accent);
		border-bottom: 1px solid var(--color-accent);
		padding: 5px 0;
		font-size: 14px;
		font-weight: 500;
		height: 52px;
		overflow: hidden;
	}


	.description {
		padding: 10px 0 25px 0;
		font-size: 14px;
		color: #000;
		font-weight: 400;

		.link {
			text-align: left;
			margin: 15px 0 0 0;

			.btn-secondary {
				padding: 5px 20px;
				text-transform: uppercase;
				font-weight: 600;
				border-radius: 100px !important;
				border-color: #fff;
			}
		}
	}

	.offer-link {
		padding-top: 25px;
	}
}