.blog-page-layout-v1 {
	padding-top: 100px;
	padding-bottom: 50px;

	@media (max-width: 992px) {
		padding-top: 80px;
	}

	@media (max-width: 767px) {
		padding-top: 70px;
	}

	.box {
		.body {
			padding: 0;
		}

		ul {
			margin: 0 0 0 0;

			li {
				&:last-child {
					a {
						border: none !important;
					}
				}

				a {
					text-decoration: none;
					display: block;
					padding: 7px 15px;
					border-bottom: 1px solid rgb(0 0 0 / 20%);
					font-size: 14px;
					font-weight: 500;
				}
			}
		}
	}

	.flex {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.main-title {
		display: inline-block;
	}

	.filter {
		display: inline-block;
		text-align: right;

		a {
			border-top: 1px solid var(--color-accent);
			border-bottom: 1px solid var(--color-accent);
			padding: 8px 8px;
			display: block;
			margin-bottom: 20px;
			font-weight: 500;
			cursor: pointer;
			text-align: center;

			@media(max-width:400px) {
				font-size: 14px;
				padding: 8px 0px;
			}
		}
	}

	.title {
		display: flex;
		align-items: center;


	}

	h1 {
		font-size: 35px;
		color: #000;
		text-transform: uppercase;
		margin: 0 0 20px 0;

		@media (max-width: 767px) {
			font-size: 30px;
		}
	}

}