.index-page-layout {
	h2 {
		color: #000;
		font-weight: 600;
		font-size: 35px;
		text-decoration: none;
		text-transform: uppercase;
		margin-bottom: -60px;

		@media (max-width: 991px) {
			font-size: 30px;
			display: block;
			width: 100%;
			text-align: center;
		}
	}

	.btn-line {
		text-align: center;

		@media(min-width: 993px) {
			text-align: right;
		}
	}

	.index-offers {
		.owl-carousel {
			.owl-nav {
				button {
					top: 23%;
				}
			}

		}

		.blog-card-v1 {
			.blog-body {
				padding-bottom: 25px;
			}

			.offer-link {
				padding-bottom: 0px;
			}
		}
	}

	.owl-carousel {
		.owl-nav {
			button {
				position: absolute;
				font-size: 55px;
				opacity: 0.3;
				line-height: 0;
				transform: translateY(-50%);

				@media(max-width: 575px) {
					opacity: 1.0;

					span {
						font-family: "Font Awesome 5 Free";
						font-weight: 900;
						-webkit-font-smoothing: antialiased;
						display: inline-block;
						font-style: normal;
						font-feature-settings: normal;
						font-variant: normal;
						text-rendering: auto;
						line-height: 1;
						color: white;
						background: #000;
						font-size: 30px;
						padding: 10px 10px;
					}
				}

				&.owl-next {
					@media(max-width: 767px) {
						right: -30px;
					}

					@media(max-width: 575px) {
						right: 0px;
					}
				}

				&.owl-prev {
					@media(max-width: 767px) {
						left: -30px;
					}

					@media(max-width: 575px) {
						left: 0px;
					}
				}
			}
		}
	}

	.unitsoftheday {
		.unit-list-section {

			.owl-carousel {
				@media(min-width: 768px) {
					display: grid;
					grid-template-columns: repeat(3, 1fr);
					gap: 30px;

				}

				@media(max-width: 991px) {
					grid-template-columns: repeat(2, 1fr);
				}

				@media(max-width: 767px) {
					display: block;
				}

				.owl-nav {
					button {
						top: 28%;
					}
				}

			}
		}
	}

	.index-blog {
		.owl-carousel {
			.owl-nav {
				button {
					top: 23%;

					&.owl-next {

						right: -30px;

						@media(max-width: 575px) {
							right: 0px;
						}
					}

					&.owl-prev {
						left: -30px;

						@media(max-width: 575px) {
							left: 0px;
						}
					}
				}
			}

		}

		.blog-boxes {
			position: relative;
		}

		.blog-card-v1 {
			border-top: unset;
			border-bottom: unset;
			padding: 0px;
			margin-bottom: 0px;

			.blog-body {
				padding-bottom: 25px;
			}
		}
	}

	.blogentry {
		width: 100%;
	}

	.owl-carousel {
		.owl-dots {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0;
			width: 100%;
			padding-top: 20px;
			padding-bottom: 30px;

			.owl-dot {
				width: 15px;
				height: 15px;
				background: #b5b5b5;
				margin: 0 5px;
				border-radius: 100px;

				&.active {
					background: var(--color-primary);
				}
			}
		}
	}

	.feedbacks-v1 {
		.owl-carousel {
			.owl-dots {
				display: none;

			}
		}
	}

	.index-info {
		h2 {
			color: #000;
			font-weight: 600;
			font-size: 35px;
			text-decoration: none;
			text-transform: uppercase;
			margin: 0 0 15px 0;

			@media (max-width: 767px) {
				font-size: 30px;
				margin-bottom: 30px;
				text-align: center;
			}
		}

		.info-box {
			margin-bottom: 50px;

			h3 {
				color: #000;
				font-weight: 600;
				font-size: 35px;
				text-decoration: none;
				text-transform: uppercase;
				margin: 0 0 15px 0;

				@media (max-width: 767px) {
					font-size: 26px;

				}
			}

			p {
				font-size: 15px;
				font-weight: 400;
			}
		}

	}
}