.offer-page-layout-v1 {
	padding-top: 100px;
	padding-bottom: 50px;

	@media (max-width: 992px) {
		padding-top: 80px;
	}

	@media (max-width: 767px) {
		padding-top: 70px;
	}

	.title {
		display: flex;
		align-items: center;

		h1 {
			width: 80%;

			@media (max-width: 992px) {
				width: 60%;
			}
		}

		a {
			width: 20%;
			border-top: 1px solid var(--color-accent);
			border-bottom: 1px solid var(--color-accent);
			padding-top: 8px;
			padding-bottom: 8px;
			display: block;
			margin-bottom: 20px;
			font-weight: 500;
			cursor: pointer;

			@media (max-width: 992px) {
				width: 40%;
			}
		}
	}


	h1 {
		font-size: 35px;
		color: #000;
		text-transform: uppercase;
		margin: 0 0 20px 0;

		@media (max-width: 767px) {
			font-size: 30px;
		}
	}

	.blog-card-v1 {
		.description {
			padding-bottom: 0;
			border-bottom: 0 !important;
			margin-bottom: 50px;
		}
	}
}