.unit-card-v3 {
	height: 100%;

	.unit-container {
		display: flex;
		flex-direction: column;
		height: 100%;
		position: relative;

		.close-map-btn {
			position: absolute;
			right: 5px;
			top: 5px;
			padding: 0px 10px;
			font-size: 26px;
			line-height: 28px;
			z-index: 3;
			color: var(--color-black);
			background-color: var(--color-white);
			cursor: pointer;
			border-radius: 5px;
			border-radius: var(--border-radius);
			border: 1px solid var(--color-rey-dark);

		}

		.unit-link {
			color: var(--font-color-main);
			text-decoration: none;

			&:hover {
				color: var(--font-color-main);
				text-decoration: none;
			}
		}

		.unit-map {
			display: none;
			height: 218px;
			overflow: hidden;

			@media(max-width:1199px) {
				height: 165px;
			}

			@media(max-width:991px) {
				height: 188px;
			}

			@media(max-width:767px) {
				height: 280px;
			}

		}


		.img-col {
			position: relative;
			width: 100%;
			aspect-ratio: 4/3;

			.fav-icon {
				position: absolute;
				top: 5px;
				right: 5px;
				color: #fff;
				cursor: pointer;
				z-index: 2;
				font-size: 22px;
				width: 35px;
				text-align: center;

				.fa {
					&.fa-heart {
						color: var(--color-orange);
					}
				}
			}

			.popup-buttons {
				position: absolute;
				left: 10px;
				top: 10px;
				margin-right: 5px;

				.button {
					padding: 0 5px;
					line-height: 24px;
					min-width: 75px;
					text-align: center;
					z-index: 2;
					color: var(--font-color-main);
					background-color: rgba(255, 255, 255, 0.8);
					font-size: 14px;
					cursor: pointer;
					border-radius: 20px;
					display: inline-block;

					&:hover {
						color: var(--color-white);
						background-color: rgba(55, 55, 55, 0.8);
					}

				}
			}


			img {
				width: 100%;
				object-fit: cover;
				height: 100%;
				object-position: center center;
			}

			.img-content {

				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				overflow: hidden;
				border-top-left-radius: var(--border-radius);
				border-top-right-radius: var(--border-radius);

			}

		}

		.unit-facts {
			padding: 0px 0px;
			font-size: 13px;

			.unit-title {
				padding: 10px 0px;
				font-size: 17px;
				font-weight: 600;
				text-transform: uppercase;
			}

			.unit-infomation {
				border-top: 1px solid #000;
				border-bottom: 1px solid #000;
				font-size: 14px;
				margin: 10px 0px;
				padding: 5px 0px;
			}

			.place {
				font-size: 13px;
			}

			.unit-desc {
				display: -webkit-box;
				-webkit-box-orient: vertical;
				overflow: hidden;
				line-height: 1.5;
				-webkit-line-clamp: 3;
				position: relative;
				font-size: 14px;
				margin-bottom: 20px;
				height: 64px;
			}



			.flex-bottom {
				padding-bottom: 10px;
			}
		}

	}
}



.popover {
	border-radius: 0;
	background-color: var(--color-white);
	border: 1px solid #C0C0C0;
	font-size: var(--font-size-md);
	box-shadow: inherit;
	width: 100%;
	max-width: 520px;

	.popover-header {
		border-radius: 0;
		background-color: var(--color-grey-light);
		margin-bottom: 10px;
		padding: 10px 10px;
		font-size: var(--font-size-md);
		font-weight: normal;
	}

	.arrow {
		&:after {
			border-top-color: #C0C0C0 !important;
		}
	}

	.popover-body {
		padding: 10px;


		ul {
			overflow-y: scroll;
			height: 180px;
			padding-right: 10px;


			li {
				position: relative;
				margin-bottom: 2px;
				padding-left: 14px;
				list-style: none;
				font-size: var(--font-size-md);
				font-family: var(--font-family-main);
				margin-bottom: 3px;

				&:before {
					background-color: var(--color-primary);
					width: 5px;
					height: 5px;
					position: absolute;
					content: "";
					left: 0;
					top: 6px;
					border-radius: 50%;

				}
			}
		}
	}
}