.static-view {

	&.kurtaxe-v1 {
		h2 {
			color: var(--color-orange);
		}

		img {
			width: 100%;
		}

		ul {
			list-style: disc;
			padding-left: 15px;
		}
	}

	.newhauser-units {
		width: 100%;

		.unit-card-v2 {
			display: -webkit-box;
			display: flex;
			flex-wrap: wrap;
			margin-right: -15px;
			margin-left: -15px;

			.image-wrap {
				padding-right: 15px;
				padding-left: 15px;

				@media (min-width: 768px) {
					-webkit-box-flex: 0;
					flex: 0 0 41.666667%;
					max-width: 41.666667%;

				}

			}

			.content {
				padding-right: 15px;
				padding-left: 15px;

				@media (max-width: 767px) {
					width: 100%;
				}

				@media (min-width: 768px) {
					-webkit-box-flex: 0;
					flex: 0 0 58.333333%;
					max-width: 58.333333%;
					display: block;

				}

				.h5 {
					min-height: auto;
					margin-bottom: 20px;
				}

				.fav-icon {

					position: relative;
					top: -4px;
				}
			}
		}
	}
}

.paralax-img {
	width: 100%;
	height: 70vh;
	overflow: hidden;
	min-height: 400px;
	max-height: 850px;

	@media(max-width: 767px) {
		height: 40vh;
	}

	@media (max-height: 500px) {
		min-height: 300px;
	}


	img {
		width: 100%;
		position: fixed;
		object-fit: cover;
		height: 70vh;

		@media(max-width: 767px) {
			height: 40vh;
		}
	}


}