.booking-v1 {

	.booking-view {
		padding-top: var(--page-margin-top);

		@media (max-width: 767px) {
			padding-top: 60px;
		}
	}

	.step2 {
		h1 {
			border-top: 1px solid var(--color-accent);
			padding: 70px 0 100px 0;
			margin: 0;

			@media (max-width: 767px) {
				padding: 50px 0 40px
			}
		}

		.payonline {
			padding: 82px 0 0 0;

			@media (max-width: 767px) {
				padding-top: 50px;
			}

			h3 {
				color: #000;
				font-weight: 600;
				font-size: 35px;
				text-decoration: none;
				text-transform: uppercase;
			}
		}

		.thank-pera1 {
			p {
				font-size: 15px;
				color: #000;
				font-weight: 500;
			}
		}
	}



	.unit-booking-title {
		display: flex;
		align-items: center;
		align-items: center;
		justify-content: space-between;

		h1 {
			width: calc(50% - 8px);
			margin: 0;
			border-top: 1px solid var(--color-accent);
			font-size: 15px;
			font-weight: 500;
			padding-top: 10px;
			padding-bottom: 0;
			border-bottom: 1px solid var(--color-accent);
			text-transform: uppercase;
			min-height: 80px;
			height: auto;

			@media (max-width: 767px) {
				min-height: 120px;
			}
		}

		.please-fill {
			width: calc(50% - 8px);
			margin: 0;
			border-top: 1px solid var(--color-accent);
			font-size: 15px;
			font-weight: 500;
			padding-top: 10px;
			padding-bottom: 0;
			border-bottom: 1px solid var(--color-accent);
			text-transform: uppercase;
			min-height: 80px;
			height: auto;

			@media (max-width: 767px) {
				min-height: 120px;
			}
		}
	}

	.required::after {
		content: "*";
		vertical-align: super;
		font-size: var(--font-size-sm);
	}

	.label {
		display: inline-block;
	}

	.cart-details {
		padding: 0;
		background-color: transparent;
		border: 0px solid #1c1c1c;
		border-top: 1px solid var(--color-accent);
		padding-top: 80px;

		@media (max-width: 767px) {
			padding-top: 50px;
		}

		.unit-type-place {
			display: flex;
			margin: 30px 0 0 0;

			.type {
				font-size: 14px;
				color: #000;
				font-weight: 400;
				text-transform: uppercase;
				width: 50%;


				img {
					opacity: 0.5;
					height: 20px;
					margin: 0 0 4px 0;
					display: inline-block;
					width: auto;
				}
			}

			.place {
				width: 50%;

				ul {
					width: 50%;
					margin: 20px 0 0 0;

					li {
						font-size: 14px;
						color: #000;
						font-weight: 400;
						text-transform: uppercase;
					}
				}
			}
		}

		.h4 {
			color: #000;
			font-weight: 600;
			font-size: 35px;
			text-decoration: none;
			text-transform: uppercase;
			margin: 15px 0 0 0 !important;

			@media (max-width: 767px) {
				font-size: 30px;
			}
		}

		.unit-details {
			margin: 20px 0;
			color: var(--color-grey-dark);



			li {
				padding-left: 30px;
				padding-right: 15px;
				margin-top: 5px;
				position: relative;

				&:before {
					content: "\f00c";
					font-family: "Font Awesome 5 Free";
					-webkit-font-smoothing: antialiased;
					font-weight: 600;
					display: inline-block;
					position: absolute;
					left: 0;
					top: 0;
					color: #1c1c1c;
				}
			}
		}
	}

	.pd-form {
		label {
			font-weight: 500;
		}

	}

	.checks {

		display: flex;
		flex-wrap: wrap;

		.left {
			width: 50%;
			margin-top: -105px;
			position: relative;

			@media (max-width: 767px) {
				margin: 0;
				width: 100%;
			}
		}

		.button-group {
			width: 50%;
			padding: 0 8px;

			@media (max-width: 767px) {
				margin: 20px 0;
				width: 100%;
				padding: 0;
			}

			.btn {
				border-radius: 100px !important;
				font-size: 14px;
				text-transform: uppercase;
				font-weight: 500;
				padding: 0 20px;
				min-height: 40px;
				line-height: 40px;
				border: none !important;
				background: #fff;
				color: #000;
				margin: 0 10px 0 0;

				@media (max-width: 767px) {
					margin: 0 5px 0 0;
					padding: 0 8px;
					font-size: 13px;
				}

				&:hover {
					background: #000;
					color: #fff;
				}
			}
		}

		a {
			font-size: 12px;
			font-weight: 500;
			text-transform: uppercase;
		}


		.checkbox {
			label {
				font-size: 12px;
				text-transform: uppercase;
				font-weight: 500;
			}

			label {
				vertical-align: inherit;
				display: unset;
			}
		}

		a {
			color: var(--font-color-main) !important;
		}
	}


	.flow-btn {
		margin-left: 20px;
	}


	.pd-form {
		.row {
			margin-left: -8px;
			margin-right: -8px;
		}

		.col-xl-6 {
			padding-left: 8px;
			padding-right: 8px;
		}

		.form-group {
			>label {
				text-transform: uppercase;
				font-size: 14px;
			}

			.form-control {
				border: none;
				min-height: 45px;
				text-transform: uppercase;
				font-size: 15px;
				color: #000;
				font-weight: 400;
			}

			textarea.form-control {
				height: 200px;
			}
		}
	}
}

.pay-online-box {
	color: #000;
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 30px;
}

.amount-options-list {
	margin: 20px 0;

	.amount-option-btn {
		.amount-or {
			text-align: center;
			margin: 15px 0;
			width: 100%;
			max-width: 305px;
			text-transform: uppercase;
			color: #000;
			font-weight: 500;
		}

		.btn-default {
			width: 100%;
			max-width: 305px;
			border: none;
			font-size: 14px;
			padding: 12px 20px;
			border-radius: 100px !important;
			color: #000;
			font-weight: 500;
			text-transform: uppercase;

			&:hover {
				color: #fff;
			}
		}
	}

}