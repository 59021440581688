.eigentuemerservice {

	h2 {
		text-transform: uppercase;
	}

	.services {
		margin-bottom: 20px;
	}

	.service-box {
		background: var(--color-white);
		padding: 15px 15px 0;

		.point {
			display: flex;
			align-items: flex-start;
			gap: 8px;
			line-height: 1.5;
			margin-bottom: 15px;

			i {
				flex-shrink: 0;
				margin-top: 3px;
				font-size: 20px;
			}
		}
	}

	ul {
		display: block;
		margin-block-start: 1em;
		margin-block-end: 1em;
		margin-inline-start: 0;
		margin-inline-end: 0;
		padding-inline-start: 40px;
		list-style-type: disc;
		list-style-position: outside;

		li {
			line-height: 2;
		}
	}

	.contact-sidebar-v1 {

		ul {
			margin-block-start: 0em;
			margin-block-end: 0em;
			padding-inline-start: 0px;

		}

		.image {
			height: 319px;
			overflow: hidden;

			@media(max-width:450px) {
				height: 300px;
			}

			img {
				width: 100%;
			}
		}

		.pic-text {
			font-size: 12px;
			padding: 5px 0;
		}
	}
}