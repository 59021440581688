.footer-v1 {
	background-color: transparent;
	position: relative;
	background: var(--bg-page);

	@media (max-width: 767px) {
		padding: 0 15px;
	}

	.container {
		border-top: 1px solid var(--color-accent);

		@media (max-width: 767px) {
			padding: 0 0;
		}
	}

	.main-footer {
		color: var(--footer-font-color);
		/* border-top: 1px solid var(--footer-border-top-color); */

		@media (max-width: 767px) {
			padding-top: 25px !important;
			padding-bottom: 0 !important;
		}


		.row {
			margin-right: -10px;
			margin-left: -10px;

			.col-lg-3,
			.col-lg-4,
			.col-lg-5 {
				padding-left: 10px;
				padding-right: 10px;
			}
		}

		.headline {
			font-size: 16px;
			color: var(--footer-font-color);
			font-weight: bold;
			text-transform: uppercase;
		}

		.mob-mb-10 {
			@media (max-width: 991px) {
				margin-bottom: 10px !important;
			}
		}

		.first-col {
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;

			.line-box {
				.list-unstyled {
					min-height: 100px;
					border-bottom: 1px solid var(--color-accent);
					margin-bottom: 20px;
					padding-bottom: 15px;
					margin-top: -3px;

					li {}
				}
			}

			.h2 {
				font-size: 15px;
				font-weight: 500;
				text-transform: uppercase;
				margin: 0 0 15px 0;
				height: 97px;
				border-bottom: 1px solid var(--color-accent);

				@media (max-width: 767px) {
					height: auto;
					padding-bottom: 20px;
				}

				a {
					color: #000;
					text-decoration: none;
				}
			}



			ul {
				margin: 0 0 25px 0;
				color: #000;

				li a {
					color: #000;
					text-decoration: none;
					font-size: 14px;
					font-weight: 500;
					text-transform: uppercase;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}

		.sec-col {
			.h2 {
				font-size: 15px;
				font-weight: 500;
				text-transform: uppercase;
				margin: 0 0 15px;
			}

			.footer-newsletter {
				width: 100%;
				padding-bottom: 25px;
				border-bottom: 1px solid var(--color-accent);
				margin-bottom: 25px;
			}

			.vernetzt {
				width: 100%;
			}

			.bezahlmethoden {
				width: 100%;
			}

			ul {
				margin: 0 0 25px 0;
				color: #000;

				li {
					margin-bottom: 0.5rem !important;
					text-transform: uppercase;
				}

				li a {
					color: #000;
					text-decoration: none;
					font-size: 14px;
					font-weight: 500;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}

		.third-col {
			.h2 {
				font-size: 15px;
				font-weight: 600;
				text-transform: uppercase;
				margin: 0 0 15px;
			}

			.col-md-6 {
				padding: 0 10px;
			}

			ul {
				margin: 0 0 25px 0;
				color: #000;

				li {
					text-transform: uppercase;
				}

				li a {
					color: #000;
					text-decoration: none;
					font-size: 14px;
					font-weight: 500;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}

		.footer-logo {
			color: #fff;
			text-transform: uppercase;
			font-size: 28px;
		}

		.footer-newsletter {
			/*color: #fff;

			p {
				color: #fff !important;
			}*/
		}

		.vernetzt {
			width: 100%;
			padding-bottom: 36px;
			border-bottom: 1px solid var(--color-accent);
			margin-bottom: 25px;

			@media (max-width: 767px) {
				padding-bottom: 25px;
			}


			ul {
				display: flex;
				align-items: center;
				margin: 0;

				li {
					margin: 0 20px 0 0;

					a {
						&:hover {
							img {
								filter: brightness(0.5);
							}
						}

						img {
							height: 22px;
							width: 22px;
						}
					}
				}
			}
		}

		.bezahlmethoden {
			ul {
				display: flex;
				align-items: center;
				margin: 0;

				li {
					margin: 0 20px 0 0;
				}
			}
		}


		.logo {
			width: 250px;
			height: auto;
			margin-top: -40px;
			padding-bottom: 20px;
		}

		.openings {

			color: #fff;

			div {
				padding-top: 10px;
			}
		}

		a {
			color: var(--footer-font-color);
		}

		.social-icons {

			li {

				a {
					color: var(--footer-font-color);
				}

				.fa {
					font-size: 20px;
					color: var(--footer-font-color);

				}
			}

		}




		#button-addon1 {
			color: #ffc371;
		}

		.btn-link {
			padding: 0;
			border: transparent;
			background: transparent;
			box-shadow: none;
			outline: none;

			img {
				width: 28px;
			}

			.fa {
				color: var(--footer-icon-color);
			}
		}

		i {
			color: #a1625e;
			font-size: 28px;
		}

		.form-control {
			border-bottom: 1px solid #000 !important;
			background: transparent;
			padding: 0 10px;
			margin-left: 10px;
			max-width: 300px;
			text-align: left;
		}

		.form-control::placeholder {
			font-size: 0.95rem;
			color: #aaa;
			font-style: italic;
		}

		.form-control.shadow-0:focus {
			box-shadow: none;
		}
	}

	.copyright {
		background-color: var(--footer-copyright-bg-color);
		color: var(--footer-copyright-font-color);

		a {
			color: var(--footer-copyright-font-color);
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}

		.v-office-logo {
			float: left;
			padding-right: 20px;

			img {
				width: 50px;
			}
		}
	}

}