.search-main-form-v1 {
	margin: 0;

	.select-bl {
		padding-left: 0 !important;

		.form-control {
			padding-left: 7px !important;
		}
	}

	.form-bx-area,
	.datepicker-trigger,
	.form-group {
		position: relative;



		.fa {
			position: absolute;
			right: 1rem;
			top: 11px;
			color: var(--color-grey-dark);
			display: block;
			line-height: 21px;
			font-size: 15px;
			display: none;

			@media (max-width: 992px) {
				font-size: 16px;
				bottom: 13px;
				right: 10px;
			}
		}

	}


	.search-box-inner {
		display: flex;
		flex-wrap: wrap;

		select {
			&.form-control {
				height: 55px;
			}
		}

		.form-group {
			width: 100%;
			margin: 0;
			position: relative;
			background-color: #dbdbdb;

			border-bottom: 1px solid var(--color-accent);
			padding-left: 7px;

			.icon-arrow {
				background-image: url(RESOURCE/img/icon-arrow.svg);
				background-repeat: no-repeat;
				background-size: contain;
				width: 19px;
				height: 15px;
				position: absolute;
				right: 5px;
				display: inline-block;
				top: 16px;
				background-color: inherit;
				pointer-events: none;
			}

			.icon-edit {
				background-image: url(RESOURCE/img/icon-pen.svg);
				background-repeat: no-repeat;
				background-size: contain;
				width: 19px;
				height: 15px;
				position: absolute;
				right: 5px;
				display: inline-block;
				top: 16px;
				background-color: inherit;
				pointer-events: none;
			}

			&.reiseziel {
				width: 100%;
				padding-left: 10px;
			}

			&.wann {
				width: 100%;
				padding-left: 10px;
			}

			&.wie-viele {
				width: 100%;
				padding-left: 10px;
			}

			.form-control {
				border-bottom: none;
			}
		}

		.schlafzimmer {
			margin-right: 15px;
		}

		.search-btn {
			width: 10%;
			margin-left: 7px;

			a {
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 0;
				color: #fff;
				background: #1c1c1c;
				position: static;
				border: none;
				font-size: 20px;
			}
		}
	}



	.form-control {
		border-radius: 3px;
		font-size: 16px;
		cursor: pointer;
		border: none;
		border-radius: 0;
		height: auto;
		padding-right: 30px !important;
		font-size: 14px !important;
		padding: 10px 0 !important;
		border: none;
		border-bottom: 1px solid var(--color-accent);
		background: transparent;
		text-transform: uppercase;
		font-weight: 500;
		color: #000;
		direction: ltr !important;

		@media (min-width:1200px) {
			font-size: 16px;
			padding: 14px 1rem;
			min-height: 55px;
		}

		@media (max-width:1199px) {
			font-size: 13px;
			min-height: 55px;
		}

		@media (max-width:992px) {
			font-size: 16px;
			height: auto;

		}


		&.btn-lg {

			font-size: 16px;
			border-top: 0;

			@media (min-width:1200px) {
				font-size: 16px;
				padding: 14px 10px;
				min-height: 55px;
			}

			@media (max-width:1199px) {
				font-size: 13px;
				min-height: 55px;
			}

			@media (max-width:992px) {
				font-size: 16px;
				height: auto;
			}

		}



	}

	.form-control:disabled,
	.form-control[readonly] {
		/* background-color: var(--color-white); */
	}

	.list-group-item {
		color: var(--font-color-main);
		text-decoration: none;
		border-radius: 0 !important;


		&:hover {
			color: var(--font-color-main);
			text-decoration: none;
		}

		&.active {
			background-color: transparent;
			border: 1px solid #1c1c1c;
		}
	}


}


input::-webkit-input-placeholder {
	/* Edge */
	color: red;
}

input:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: red;
}

input::placeholder {
	color: red;
}